import styled, { css } from 'styled-components';
import { ifNotProp, ifProp, theme } from 'styled-tools';

import { minWidth, units } from '@calm-web/design-system';

export const APP_WRAPPER_BOTTOM_MARGIN = 104;

export const Wrapper = styled.div<{
	$isZenMode: boolean;
	$isLargePlayer?: boolean;
	$isTeamsApp?: boolean;
	$isBannerVisible?: boolean;
	$isWebAppRedesign?: boolean;
}>`
	position: ${p => (p.$isTeamsApp ? '' : 'relative')};
	margin-bottom: ${p => (p.$isLargePlayer ? '0px' : `${APP_WRAPPER_BOTTOM_MARGIN}px`)};
	opacity: ${p => (p.$isZenMode ? '0' : '1')};
	pointer-events: ${p => (p.$isZenMode ? 'none' : 'auto')};
	transition: opacity ${theme('animation.transition')};
	padding-top: ${ifProp('isBannerVisible', '40px', '0')};

	${p =>
		p.$isLargePlayer
			? css`
					min-height: 100vh;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
			  `
			: ''};

	${ifNotProp(
		'$isWebAppRedesign',
		css`
			${minWidth('tablet')} {
				padding-bottom: 100px;
			}
		`,
	)};
`;

export const Main = styled.main<{
	$isZoomApp?: boolean;
}>`
	overflow: hidden;

	${minWidth('desktop')} {
		padding-left: ${theme('app.sidebarWidth')};
	}

	${p =>
		p.$isZoomApp
			? 'padding-left: 0 !important;' // important needed to override minWidth desktop above^
			: ''};
`;

export const Container = styled.div<{
	$isVisible: boolean;
	$isZoomApp?: boolean;
	$hasBanner?: boolean;
	$bottomOffset?: number;
	$isLargePlayer?: boolean;
}>`
	height: 100%;
	opacity: ${p => (p.$isVisible ? '1' : '0.1')};
	padding: ${p => (p.$hasBanner ? 'calc(min(64px,20vh)) 0 16px' : 'calc(min(64px,20vh)) 16px 16px')};
	padding-top: 0;
	transition: opacity ${theme('animation.transition')};
	visibility: ${p => (p.$isVisible ? 'visible' : 'hidden')};
	margin-bottom: ${p =>
		p.$isLargePlayer
			? units(2)
			: `${p.$bottomOffset ? Math.max(p.$bottomOffset - APP_WRAPPER_BOTTOM_MARGIN, 0) : 0}px`};
	${minWidth('tablet')} {
		margin-bottom: ${p =>
			p.$isLargePlayer
				? units(2)
				: `${p.$bottomOffset ? Math.max(p.$bottomOffset - APP_WRAPPER_BOTTOM_MARGIN, 0) : 0}px`};
	}

	${minWidth('tablet')} {
		padding: ${p => (p.$hasBanner ? '80px 16px 16px' : '64px 16px 32px')};
	}

	${p =>
		!p.$isZoomApp &&
		css`
			${minWidth('desktop')} {
				padding: 24px 48px 96px;
			}
		`};
`;

export const LoaderWrapper = styled.div<{
	$isZoomApp?: boolean;
}>`
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;

	${minWidth('desktop')} {
		left: ${theme('app.sidebarWidth')};
	}

	${p =>
		p.$isZoomApp
			? `${minWidth('desktop')} {
        left: 0;
      }`
			: ''};
`;
